import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import usePersistentState from "../hooks/usePersistentState";
// import portals from "../data/portals.json";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [enteredPromo, setEnteredPromo] = usePersistentState("enteredPromo", false);
  const [showIntro, setShowIntro] = usePersistentState("showIntro", true);
  const [userId, setUserId] = useState(null);
  const [vifpNumber, setVifpNumber] = usePersistentState("vifpNumber", null);
  const [quizResultId, setQuizResultId] = useState(null);
  const [isTrade, setIsTrade] = useState(false);
  const [allVideosPlaying, setAllVideosPlaying] = useState(true);

  const [fromPortal, setFromPortal] = useState(false);

  const [isPromoOver, setIsPromoOver] = useState(false);

  const [portalList, setPortals] = useState();

  const portals = useMemo(
    () => [
      {
        name: "Paradise Plaza",
        key: "paradise-plaza",
        href: "/portals/paradise-plaza",
        eventTag: "portal_paradise-plaza",
        titleLogo: "CK_Lockups_TM_Paradise_Plaza.svg"
      },
      {
        name: "Calypso Lagoon",
        key: "calypso-lagoon",
        href: "/portals/calypso-lagoon",
        eventTag: "portal_calypso-lagoon",
        titleLogo: "calypso-lagoon.svg"
      },
      {
        name: "Lokono Cove",
        key: "lokono-cove",
        href: "/portals/lokono-cove",
        eventTag: "portal_lokono-cove",
        titleLogo: "lokono-cove.svg"
      },
      {
        name: "Starfish Lagoon",
        key: "starfish-lagoon",
        href: "/portals/starfish-lagoon",
        eventTag: "portal_starfish-lagoon",
        titleLogo: "starfish-lagoon.svg"
      },
      {
        name: "Pearl Cove Beach Club",
        key: "pearl-cove",
        href: "/portals/pearl-cove",
        eventTag: "portal_private-portal",
        titleLogo: "pearl-cove.svg",
        cover: "object-[50%_40%]"
      }
    ],
    []
  );

  useEffect(() => {
    const mountArray = () => {
      let array = [...portals];
      let currentIndex = array.length,
        randomIndex;
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
      }

      return array;
    };

    setPortals(mountArray);
  }, [portals, setPortals]);

  useEffect(() => {
    setIsTrade(
      window.location.hostname === process.env.REACT_APP_FRONTEND_TRADE_DOMAIN ||
        window.location.hostname === "carnivalcelebrationkeytrade.teamdigital.com"
    );
  }, [setIsTrade]);

  const currentPortalArr = portals.filter((portal) => {
    return new Date(portal.release_timestamp) > Date.now();
  });
  const currentPortal = currentPortalArr[0];

  const [currentPage, setCurrentPage] = useState("home");
  const titleMap = useMemo(() => {
    return {
      home: "Home",
      "contact-us": "Contact Us",
      rules: "Official Rules",
      error: "Error"
    };
  }, []);

  useEffect(() => {
    if (currentPage === "home") {
      document.title = "Carnival | Celebration Key™";
    } else if (titleMap[currentPage]) {
      document.title = "Carnival | Celebration Key™ | " + titleMap[currentPage];
    }
  }, [currentPage, titleMap]);

  useEffect(() => {
    setIsPromoOver(new Date(process.env.REACT_APP_PROMO_OVER_DATE) < new Date(Date.now()));
  }, [setIsPromoOver]);

  return (
    <UserContext.Provider
      value={{
        enteredPromo,
        setEnteredPromo,
        showIntro,
        setShowIntro,
        userId,
        setUserId,
        quizResultId,
        setQuizResultId,
        vifpNumber,
        setVifpNumber,
        isTrade,
        setIsTrade,
        allVideosPlaying,
        setAllVideosPlaying,
        setCurrentPage,
        currentPortal,
        fromPortal,
        setFromPortal,
        isPromoOver,
        portalList
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUserContext() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }

  return context;
}
