import React from "react";
import PropTypes from "prop-types";

const ModuleCopyHeadline = ({ children, theme, align = "center", id, mobilePadding = true }) => {
  const textColor = theme === "light" ? "text-white" : "text-dark-blue";
  const mobileMargin = mobilePadding === true ? "mb-4" : "mb-0";
  return (
    <h2
      id={id}
      className={`text-millik text-[32px] leading-[35px] md:text-[45px] md:leading-[50px] lg:mb-6 ${textColor} text-${align} ${mobileMargin}`}
    >
      {children}
    </h2>
  );
};

ModuleCopyHeadline.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  theme: PropTypes.oneOf(["light", "dark"]).isRequired
};

export default ModuleCopyHeadline;
