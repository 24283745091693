import React, { useRef, useEffect } from "react";
import { awsUrl } from "../../helpers";
import { useScrollDetectors } from "../../hooks/useScrollDetectors";
import { useUserContext } from "../../context/UserContext";

import { UnlockedPortalWrapper } from "../../components/UnlockedPortal/Parts";
import UnlockedPortalIntro from "../../components/UnlockedPortal/UnlockedPortalIntro";
import Video from "../../common/Video";

import { useGSAP } from "../../gsap/react";
import { gsap } from "../../gsap";
import { SplitText } from "../../gsap/SplitText";

import { FlyThrough, SideBySide } from "../../components/UnlockedPortal/Modules";
import {
  ModuleCopyBody,
  ModuleCopyHeadline,
  ModuleSideOneThird,
  ModuleSideTwoThirds
} from "../../components/UnlockedPortal/Modules/Parts";

gsap.registerPlugin(SplitText, useGSAP);

const Retail = () => {
  const { setCurrentPage, setAllVideosPlaying, allVideosPlaying } = useUserContext();
  setCurrentPage("lokono-cove");
  const headlineTl = useRef();

  useScrollDetectors("retail-portal");

  useEffect(() => {
    document.title = "Carnival | Celebration Key™ | Lokono Cove";
  }, []);

  const handleVideo = () => {
    if (allVideosPlaying) {
      setAllVideosPlaying(false);
    } else {
      setAllVideosPlaying(true);
    }
  };

  useGSAP(() => {
    let titleRef = new SplitText("#market-headline", { type: "chars, words" });
    let captionRef = new SplitText("#market-body", { type: "lines" });

    const splitRevert = () => {
      captionRef.revert();
    };
    const splitRevertHeadline = () => {
      titleRef.revert();
    };

    headlineTl.current = gsap
      .timeline({
        scrollTrigger: {
          start: "top center",
          trigger: "#market-flythrough"
          //markers: true
        }
      })

      .from(titleRef.chars, {
        duration: 0.65,
        x: 10,
        autoAlpha: 0,
        stagger: 0.05,
        onComplete: splitRevertHeadline
      })
      .from(
        captionRef.lines,
        {
          duration: 0.65,
          y: 20,
          autoAlpha: 0,
          stagger: 0.03,
          onComplete: splitRevert
        },
        ">"
      )
      .fromTo("#market-link", { opacity: 0 }, { opacity: 1 });
  }, {});

  return (
    <UnlockedPortalWrapper bgColorClass="bg-[#00c8d3]" gaPrefix={`rp`}>
      <div className="absolute z-[99] mt-2 right-2">
        {" "}
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => handleVideo()}
          onClick={() => handleVideo()}
          aria-label={(allVideosPlaying ? "Pause" : "Play") + " all videos"}
          className={"absolute top-0 right-0 block z-50 bg-dark-blue px-1"}
        >
          {!allVideosPlaying && (
            <span
              aria-hidden
              className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white"
            >
              play_arrow
            </span>
          )}
          {allVideosPlaying && (
            <span className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white">
              pause
            </span>
          )}
        </div>
      </div>
      <UnlockedPortalIntro isPlaying={allVideosPlaying} portal="retail" />
      <FlyThrough
        videoName="portals/welcome/unlocked/flythrough"
        media={`image`}
        imgSrc={`${awsUrl}/media/portals/retail/unlocked/lokono-cove`}
        isPlaying={false}
        headline="Bring home a piece of&nbsp;paradise"
        bodyCopy="The name Lokono Cove draws inspiration from the Lucayans, the gentle island dwellers and craftsmen who were Grand Bahama’s earliest settlers. With charming shops, a Bahamian straw market, and more, this Portal offers a great selection of locally made, artisanal&nbsp;goods."
      ></FlyThrough>
      <SideBySide mobileReverse={true} classes={"bahamian-art"}>
        <ModuleSideOneThird className="bg-white">
          <div className="px-4 md:px-10 text-center">
            <img
              className="mx-auto mb-[25px] lg:mb-[25px] w-[80px] lg:w-[90px]"
              src={`${awsUrl}/media/portals/retail/icon.svg`}
              alt=""
            />
            <ModuleCopyHeadline theme={"dark"}>
              Enjoy beautiful Bahamian&nbsp;art
            </ModuleCopyHeadline>
            <ModuleCopyBody theme={"dark"}>
              Take a break from the sun and immerse yourself in Bahamian art and culture in Lokono
              Cove. Learn about Bahamian ceramics, take in vibrant wall murals painted by local
              artists, and&nbsp;more.
            </ModuleCopyBody>
          </div>
        </ModuleSideOneThird>
        <ModuleSideTwoThirds>
          <div className="w-full h-full relative overflow-hidden">
            <Video
              media={`video`}
              poster={"portals/retail/unlocked/basket-weaving"}
              name={"portals/retail/unlocked/basket-weaving"}
              autoplay={allVideosPlaying}
              isPlaying={allVideosPlaying}
              gradientTopper={false}
            ></Video>
          </div>
        </ModuleSideTwoThirds>
      </SideBySide>
      <div className={`border-b-[15px] border-[#052049]`}></div>

      {/* <SideBySide mobileReverse={false} classes={"welcome-to-adult"}>
        <ModuleSideHalf className="bg-dark-blue">
          <div className="w-full h-full relative overflow-hidden">
            <div
              className="absolute bg-cover h-full w-full bg-bottom"
              style={{
                backgroundImage:
                  "url(" + awsUrl + "/media/portals/family-friendly/unlocked/splashpad.jpg?v=1)"
              }}
            />
          </div>
        </ModuleSideHalf>
        <ModuleSideHalf className="bg-white">
          <div className="px-4 md:px-12 text-center max-w-[85%]">
            <img
              className="mx-auto mb-3 lg:mb-[25px] w-[70px] lg:w-[77px]"
              src={`${awsUrl}/media/portals/family-friendly/icon.svg`}
              alt=""
            />
            <ModuleCopyHeadline theme={"dark"}>Visit Starfish&nbsp;Lagoon</ModuleCopyHeadline>
            <ModuleCopyBody theme={"dark"}>
              There’s fun for all-ages and memories to be made together in our
              family-friendly&nbsp;Portal.
              <div className="block mt-[30px]">
                {" "}
                <Link
                  to={"/portals/starfish-lagoon"}
                  className="font-semibold"
                  onClick={() => {
                    triggerGAEvent("rp_starfish-lagoon");
                  }}
                >
                  <Button color={`bg-[#052049]`} className={`px-16 lg:px-24 text-white`}>
                    Explore now
                  </Button>
                </Link>
              </div>
            </ModuleCopyBody>
          </div>
        </ModuleSideHalf>
      </SideBySide> */}
    </UnlockedPortalWrapper>
  );
};

export default Retail;
