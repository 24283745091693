import React from "react";
import { CopyBlock, PortalList } from "../DiscoverYourParadise";

const DiscoverYourParadise = () => {
  return (
    <section id="section-discover" className="bg-white" data-tag="section_3_portal" data-pixel="">
      <CopyBlock />
      <PortalList />
    </section>
  );
};

export default DiscoverYourParadise;
