import React, { useState } from "react";
import { Link } from "react-router-dom";
import { triggerGAEvent } from "../../../helpers";
import PropTypes from "prop-types";

import { useUserContext } from "../../../context/UserContext";
import { NavBarDropdown, NavBarHamburger } from "../../NavBar";

const UnlockedPortalNav = ({ bgColorClass, gaPrefix }) => {
  const [navVisible, setNavVisible] = useState(false);
  const { isTrade, setFromPortal } = useUserContext();

  return (
    <nav
      className={`fixed z-[100] w-full h-[55px] flex justify-center items-center ${bgColorClass} -mt-[55px]`}
    >
      <div className="container mx-auto px-4 text-white flex justify-between items-center">
        <Link
          tabIndex={0}
          to={`/#section-discover`}
          className="flex items-center"
          onClick={() => {
            setNavVisible(true);
            setFromPortal(true);
            triggerGAEvent(`${gaPrefix}_nav_t_home`);
          }}
        >
          <div className="w-[35px] h-[35px] mb-2 self-center">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-full h-full"
            >
              <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
              <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
            </svg>
          </div>
          <span className="uppercase ml-1 text-sm font-bold ">Home</span>
        </Link>
        <div className="flex items-center">
          {!isTrade && (
            <div className="absolute left-1/2 -translate-x-1/2 lg:relative lg:translate-x-0 lg:left-auto lg:mr-20">
              <a
                href={`https://www.carnival.com/cruise-search?pageNumber=1&numadults=2&ptport=CBK&pagesize=8&sort=fromprice&showBest=true&async=true&currency=USD&locality=1`}
                onClick={() => {
                  triggerGAEvent(`${gaPrefix}_explore-sailings`);
                  window.gtag("event", "conversion", {
                    allow_custom_scripts: true,
                    u1: "[Number of Passengers]",
                    u10: "[Military]",
                    u11: "[Page URL]",
                    u12: "[ga_cilent_id]",
                    u13: "[vifp_number]",
                    u14: "[Sail ID]",
                    u2: "[Duration]",
                    u3: "[Port]",
                    u4: "[Ship]",
                    u5: "[Destination]",
                    u6: "[Sail Date]",
                    u7: "[Stateroom]",
                    u8: "[Booking ID]",
                    u9: "[Home State]",
                    send_to: "DC-10750860/carni0/carni055+unique"
                  });
                }}
                aria-label="Explore Sailings"
                className="inline-block bg-white uppercase text-dark-blue text-[12px] lg:text-[15px] font-bold leading-none py-3 px-4 lg:px-8"
              >
                Explore&nbsp;Sailings
              </a>
            </div>
          )}
          <NavBarHamburger onClick={() => setNavVisible(true)} />
        </div>
      </div>
      <NavBarDropdown setNavVisible={setNavVisible} navVisible={navVisible} gaPrefix={gaPrefix} />
    </nav>
  );
};

UnlockedPortalNav.propTypes = {
  bgColorClass: PropTypes.string.isRequired,
  gaPrefix: PropTypes.string.isRequired
};

export default UnlockedPortalNav;
