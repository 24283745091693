import React from "react";
import { PortalListItem } from "../DiscoverYourParadise";
// import { useGSAP } from "../../gsap/react";
// import { gsap } from "../../gsap";

import { useUserContext } from "../../context/UserContext";

const PortalList = () => {
  const { portalList } = useUserContext();

  // const tl = useRef();

  // useGSAP(() => {
  //   tl.current = gsap.timeline({
  //     scrollTrigger: {
  //       start: "top 80%",
  //       end: "+=150%",
  //       trigger: "#discover-portals"
  //     }
  //   });
  //   portalList.forEach((portal) => {
  //     tl.current.from(
  //       `#discover-portal-${portal.key}`,
  //       {
  //         duration: 0.75,
  //         y: 80,
  //         autoAlpha: 0,
  //         opacity: 0,
  //         delay: 0.3,
  //         ease: "ease.in"
  //       },
  //       "<"
  //     );
  //   });
  // }, {});

  return (
    <div className="relative">
      <ul id="discover-portals" className="bg-dark-blue lg:flex lg:h-[546px] justify-evenly">
        {portalList &&
          portalList.map((portal, idx) => (
            <PortalListItem
              key={portal.name}
              id={`discover-portal-${portal.key}`}
              portal={portal}
              idx={idx}
            />
          ))}
      </ul>
    </div>
  );
};

export default PortalList;
