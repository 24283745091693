import React, { useRef, useEffect } from "react";
import { ModuleSection } from "./Parts";
import PropTypes from "prop-types";

import { gsap } from "../../../gsap";
import { useGSAP } from "../../../gsap/react";
import { useUserContext } from "../../../context/UserContext";

import SlideShow from "./SlideShow";

const Overlay = ({
  backgroundImg,
  children,
  className,
  isSlideshow = false,
  slides,
  autospeed,
  id,
  leftJustified = true,
  overlay = true,
  direction = "left",
  ariaHidden = false
}) => {
  const panTl = useRef();

  const { allVideosPlaying } = useUserContext();

  useGSAP(() => {
    if (overlay) {
      panTl.current = gsap
        .timeline({
          scrollTrigger: {
            start: "top center",
            trigger: "#portal-overlay"
            // markers: true
          },
          repeat: -1,
          yoyo: true
        })
        .set("#div-overlay", { backgroundPosition: "80% 50%", scale: 1.2 })
        .to("#div-overlay", { backgroundPosition: "20% 50%", duration: 10 }, "<");
    } else {
      panTl.current = gsap
        .timeline({
          scrollTrigger: {
            start: "top center",
            trigger: "#portal-overlay"
            // markers: true
          },
          repeat: -1,
          yoyo: true
        })
        .set("#div-overlay", { backgroundPosition: "80% 50%", scale: 1 })
        .to("#div-overlay", { backgroundPosition: "20% 50%", duration: 10 }, "<");
    }
  }, {});

  useEffect(() => {
    if (!allVideosPlaying) {
      panTl.current.kill();
    } else {
      panTl.current.restart();
    }
  });

  const leftPos = leftJustified !== true ? "lg:justify-end" : "lg:justify-start";

  const overlayPos = overlay === true ? "w-full" : "lg:w-67%";

  const overlayDir = overlay === false && direction === "left" ? "lg:left-[33%]" : "lg:right-[33%]";

  return (
    <ModuleSection className={className}>
      <div
        id="portal-overlay"
        className={`flex flex-col lg:flex-none lg:flex-row relative w-full h-full lg:flex justify-end ${leftPos}`}
      >
        {isSlideshow ? (
          <div className="relative h-screen w-full flex flex-col-reverse lg:flex-row">
            {" "}
            <div className="lg:grow-0 z-10 w-full h-[50vh] lg:h-full lg:absolute lg:left-0 lg:w-[33%] pb-3 lg:pb-0  md:flex bg-white lg:bg-white/90 items-center justify-center">
              <div className="text-center text-dark-blue px-[30px] h-full lg:h-auto flex items-center -mt-[24%] lg:m-0">
                {children}
              </div>
            </div>
            <div className="grow-0 lg:absolute w-full h-[50vh] lg:h-full top-0 left-0 slideshow-wrapper">
              <SlideShow
                slides={slides}
                isPlaying={allVideosPlaying}
                autoplaySpeed={autospeed}
                id={id}
                ariaHidden={true}
              />
            </div>
          </div>
        ) : (
          <>
            <div
              id="div-overlay"
              className={
                overlay === true
                  ? `div-overlay lg:absolute h-[50%] md:h-full w-full bg-right-bottom bg-no-repeat ${overlayPos}`
                  : `div-overlay lg:absolute h-[50%] md:h-full w-full bg-right-bottom bg-no-repeat ${overlayPos} ${overlayDir}`
              }
              style={{ backgroundImage: "url(" + backgroundImg + ")", backgroundSize: "auto 100%" }}
            />

            <div
              className={
                overlay
                  ? `z-10 w-full flex-grow lg:flex-none lg:w-[33%] pb-3 lg:pb-0 h-auto md:flex bg-white  items-center justify-center lg:bg-white/90`
                  : `z-10 w-full flex-grow lg:flex-none lg:w-[33%] pb-3 lg:pb-0 h-auto md:flex bg-white  items-center justify-center lg:bg-white`
              }
            >
              <div className="text-center text-dark-blue px-[30px] h-full lg:h-auto flex items-center">
                {children}
              </div>
            </div>
          </>
        )}
      </div>
    </ModuleSection>
  );
};

Overlay.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default Overlay;
