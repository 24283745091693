import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { awsUrl, triggerGAEvent } from "../../helpers";

const PortalListItem = ({ id, portal, idx }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <li
      id={id}
      className={`w-full lg:h-full border-t-2 border-t-dark-blue lg:border-l-2 lg:border-l-dark-blue lg:border-t-0 first:border-t-0 first:border-l-0 ${
        portal.larger ? " h-[250px]" : " h-[122px]"
      }`}
      onMouseEnter={() => !isHovered && setIsHovered(true)}
      onMouseOver={() => !isHovered && setIsHovered(true)}
      onMouseLeave={() => isHovered && setIsHovered(false)}
    >
      <Link
        aria-label={`visit ` + portal.name}
        to={portal.href}
        className="relative w-full h-full flex items-center justify-center"
        onClick={() => {
          triggerGAEvent(portal.eventTag + "-" + (idx + 1));
        }}
      >
        <div className="absolute w-full h-full object-cover overflow-hidden">
          <img
            className="hidden lg:block absolute w-full h-full object-cover transition-all duration-500"
            src={`${awsUrl}/media/portals/one-year/${portal.key}.png`}
            srcSet={`${awsUrl}/media/portals/one-year/${portal.key}@2x.png 2x`}
            alt={portal.name + " background"}
            aria-hidden={true}
          />

          <img
            className={`lg:hidden absolute w-full h-full object-cover transition-all duration-500 ${
              portal.cover ? portal.cover : ""
            }`}
            src={`${awsUrl}/media/portals/one-year/${portal.key}-m.jpg`}
            srcSet={`${awsUrl}/media/portals/one-year/${portal.key}-m@2x.jpg 2x`}
            alt={portal.name + " background"}
          />
          <div className="absolute w-full h-full opacity-40 md:opacity-25 bg-dark-blue"></div>
          <div
            className="absolute w-full h-full -translate-y-[200%] lg:translate-y-0 lg:-translate-x-[200%] animate-[shimmerDown_3s_infinite] lg:animate-[shimmer_3s_infinite] bg-gradient-to-b lg:bg-gradient-to-r from-transparent via-white/15 to-transparent"
            style={{ animationDelay: `${Number(idx) * 0.5}s` }}
          ></div>
        </div>
        {portal.key === "paradise-plaza" ? (
          <img
            aria-hidden={true}
            src={`${awsUrl}/media/portals/welcome/new/CK_Lockups_TM_Paradise_Plaza_White.svg`}
            alt={portal.name}
            className={`z-20 transition-all duration-500 ${
              portal.key === "pearl-cove" ? "w-[230px]" : ""
            } ${portal.key === "paradise-plaza" ? "w-[225px] lg:w-[245px]" : ""} ${
              isHovered && portal.key === "pearl-cove" ? "w-[305px]" : ""
            } ${isHovered && portal.key === "paradise-plaza" ? "lg:w-[415px]" : ""} ${
              isHovered ? "w-[275px] max-w-[100%]" : "w-[200px] max-w-[85%]"
            }`}
          />
        ) : (
          <img
            aria-hidden={true}
            src={`${awsUrl}/media/portals/one-year/${portal.titleLogo}`}
            alt={portal.name}
            className={`z-20 transition-all duration-500 ${
              portal.key === "pearl-cove" ? "w-[230px]" : ""
            } ${isHovered && portal.key === "pearl-cove" ? "w-[305px]" : ""} ${
              isHovered ? "w-[275px] max-w-[100%]" : "w-[200px] max-w-[85%]"
            }`}
          />
        )}
      </Link>
    </li>
  );
};

PortalListItem.propTypes = {
  id: PropTypes.string.isRequired,
  portal: PropTypes.object.isRequired
};

export default PortalListItem;
