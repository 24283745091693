/** @format */
import React, { useEffect, useRef, useState } from "react";
import { useUserContext } from "../context/UserContext.js";
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";

import { awsUrl, triggerGAEvent } from "../helpers";
import { gsap } from "../gsap";
import { SplitText } from "../gsap/SplitText";
import { useGSAP } from "../gsap/react";
import { sample } from "lodash";

gsap.registerPlugin(SplitText, useGSAP);

// const ship = sample(["Celebration", "Mardi Gras"]);
const ship = sample(["Celebration", "Mardi Gras"]);
const CompareSlider = () => {
  const [clipPos, setClipPos] = useState(50);
  const headlineShipsRef = useRef();
  const headlineTl = useRef();
  const { isTrade } = useUserContext();

  let isMobile =
    window.matchMedia("(max-width: 992px)").matches ||
    window.matchMedia("(pointer: coarse)").matches;

  const triggerShipGAEvent = () => {
    if (clipPos < 25) triggerGAEvent(ship + "-interior");
    if (clipPos > 75) triggerGAEvent(ship + "-exterior");
  };

  useGSAP(() => {
    let titleRef = new SplitText("#headline-ships", { type: "chars, words" });
    let captionRef = new SplitText(".lead", { type: "lines" });

    const splitRevert = () => {
      captionRef.revert();
    };

    const splitRevert1 = () => {
      titleRef.revert();
    };

    headlineTl.current = gsap
      .timeline({
        scrollTrigger: {
          start: "top center",
          trigger: "#carnival-ships"
          // markers: true
        }
      })
      .from(
        titleRef.chars,
        {
          duration: 0.65,
          x: 10,
          autoAlpha: 0,
          stagger: 0.03,
          onComplete: splitRevert1
        },
        "<"
      )
      .from(
        captionRef.lines,
        {
          duration: 0.75,
          y: 20,
          autoAlpha: 0,
          stagger: 0.05,
          onComplete: splitRevert
        },
        ">"
      );
  }, {});

  // Override Aria issues with React Compare Slider
  useEffect(() => {
    const button = document.querySelector('button[data-rcs="handle-container"]');

    //button.setAttribute("aria-label", "Use arrow keys to move");
    button.setAttribute("role", "button", "aria-hidden");
    button.setAttribute("draggable", true);
    button.setAttribute("aria-hidden", true);

    button.removeAttribute("aria-orientation");
    button.removeAttribute("aria-valuemin");
    button.removeAttribute("aria-valuemax");
    button.removeAttribute("aria-valuenow");
    button.setAttribute("tab-index", -1);
    button.setAttribute("role", "presentation");
    button.setAttribute("disabled", true);

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "attributes" && mutation.attributeName === "aria-valuenow") {
          mutation.target.removeAttribute("aria-valuenow");
        }
      }
    });
    observer.observe(button, { attributes: true });

    button.querySelector("div.__rcs-handle-root").removeAttribute("aria-label");
    button.setAttribute("role", "presentation");

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section
      id="carnival-ships"
      className="relative bg-dark-blue lg:min-h-screen text-white mt-[150px] lg:mt-0"
      data-tag="section_6_sliders"
      data-pixel="carni044+unique"
    >
      <div className="absolute -top-[150px] lg:top-[40px] left-0 w-full z-10 pointer-events-none">
        {!isTrade ? (
          <div>
            {" "}
            <h2
              ref={headlineShipsRef}
              id="headline-ships"
              className="text-[32px] leading-[38px] lg:text-[45px] text-millik drop-shadow-2xl lg:leading-[65px] text-center mt-[20px] lg:mt-[38px] mb-3 pointer-events-none font-bold"
              aria-label="Plan your Carnival vacation today"
            >
              <span aria-hidden="true">Plan your Carnival vacation&nbsp;today</span>
            </h2>
            <p
              className="lead text-[19px] leading-[25px] lg:text-[25px] lg:leading-[34px] w-full mx-auto max-w-[580px] text-center px-6 lg:px-4 mt-2 pointer-events-none"
              aria-label="Explore sailings to Celebration Key and the Carnival ships that’ll take you
              to paradise."
            >
              <span aria-hidden="true">
                {" "}
                Explore sailings to Celebration Key and the Carnival ships that’ll take you
                to&nbsp;paradise.
              </span>

              <p className="sr-only" aria-hidden="true">
                {" "}
                Explore sailings to Celebration Key and the Carnival ships that’ll take you
                to&nbsp;paradise.
              </p>
            </p>
          </div>
        ) : (
          <div>
            <h2
              ref={headlineShipsRef}
              id="headline-ships"
              className="text-[32px] leading-[38px] lg:text-[45px] drop-shadow-2xl lg:leading-[65px] font-thin text-center mt-[20px] lg:mt-[38px] mb-3 pointer-events-none"
              aria-label="Call your Travel Advisor to plan your Carnival vacation today"
            >
              <span aria-hidden="true">
                {" "}
                Plan your Carnival vacation with a Travel&nbsp;Advisor
              </span>
            </h2>
            <p
              className="lead text-[19px] leading-[25px] lg:text-[25px] lg:leading-[34px] w-full mx-auto max-w-[580px] text-center px-6 lg:px-4 mt-2 pointer-events-none"
              aria-hidden="true"
            >
              Call your Travel Advisor today to explore sailings to Celebration Key and the Carnival
              ships that'll take you to&nbsp;paradise.
              <p className="sr-only">
                {" "}
                Call your Travel Advisor today to explore sailings to Celebration Key and the
                Carnival ships that'll take you to&nbsp;paradise.
              </p>
            </p>
          </div>
        )}
      </div>

      {!isMobile && (
        <div className="absolute left-1/2 -translate-x-1/2 z-[100] w-full h-screen pointer-events-none ship-name">
          <svg viewBox="0 0 1366 768" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <clipPath id="revealPath">
                <rect
                  id="theSquare"
                  x={clipPos + `%`}
                  y="50%"
                  width="2000"
                  height="400"
                  fill="#ffffff"
                />
              </clipPath>
            </defs>
            {ship !== "Mardi Gras" ? (
              <text
                x="50%"
                y="70%"
                fontFamily="Tempo Std"
                letterSpacing="6px"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="125"
                className="opacity-70"
                fill="#ffffff"
              >
                CARNIVAL {ship.toUpperCase()}
              </text>
            ) : (
              <text
                x="50%"
                y="70%"
                fontFamily="Tempo Std"
                letterSpacing="6px"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="125"
                className="opacity-70"
                fill="#ffffff"
              >
                {ship.toUpperCase()}
              </text>
            )}

            <g id="textReveal" clipPath="url(#revealPath)" aria-hidden="true">
              {ship !== "Mardi Gras" ? (
                <text
                  x="50%"
                  y="70%"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontFamily="Tempo Std"
                  letterSpacing="6px"
                  fontSize="125"
                  fill="#0066FF"
                  className="opacity-70"
                >
                  CARNIVAL {ship.toUpperCase()}
                </text>
              ) : (
                <text
                  x="50%"
                  y="70%"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontFamily="Tempo Std"
                  letterSpacing="6px"
                  fontSize="125"
                  fill="#0066FF"
                  className="opacity-70"
                >
                  {ship.toUpperCase()}
                </text>
              )}
            </g>
          </svg>
        </div>
      )}

      {isMobile && (
        <div className="absolute left-1/2 -translate-x-1/2 z-[100] w-[90%] h-screen pointer-events-none ship-name">
          <svg
            viewBox="0 0 300 508"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
          >
            <defs>
              <clipPath id="revealPath">
                <rect
                  id="theSquare"
                  x={clipPos + `%`}
                  y="10%"
                  width="7500"
                  height="400"
                  fill="#ffffff"
                />
              </clipPath>
            </defs>
            <text
              x="30%"
              y="25%"
              fontFamily="Tempo Std"
              letterSpacing="3px"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize="50"
              className="opacity-70"
              fill="#ffffff"
            >
              <title> {ship.toUpperCase()} </title>
              {ship !== "Mardi Gras" && (
                <tspan x="50%" dy="0">
                  CARNIVAL
                </tspan>
              )}
              <tspan x="50%" dy="1.2em">
                {ship.toUpperCase()}
              </tspan>
            </text>
            <g id="textReveal" clipPath="url(#revealPath)" aria-hidden="true">
              <text
                x="30%"
                y="25%"
                fontFamily="Tempo Std"
                letterSpacing="3px"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="50"
                className="opacity-50"
                fill="#0066FF"
              >
                {ship !== "Mardi Gras" && (
                  <tspan x="50%" dy="0">
                    CARNIVAL
                  </tspan>
                )}
                <tspan x="50%" dy="1.2em">
                  {ship.toUpperCase()}
                </tspan>
              </text>
            </g>
          </svg>
        </div>
      )}

      {isMobile && (
        <ReactCompareSlider
          onPositionChange={(e) => {
            setClipPos(e);
          }}
          onPointerLeave={() => {
            triggerShipGAEvent();
          }}
          style={{
            height: "70vh",
            width: "100%",
            backgroundImage:
              "URL(https://s3.amazonaws.com/td.carnival/2024.celebration/assets/media/slider-arrows.svg)"
          }}
          onlyHandleDraggable={true}
          changePositionOnHover={true}
          portrait={false}
          itemOne={
            <ReactCompareSliderImage
              src={awsUrl + `/media/ships/` + ship.replace(/ /g, "") + `/exterior.jpg`}
              srcSet={awsUrl + `/media/ships/` + ship.replace(/ /g, "") + `/exterior.jpg`}
              alt={`CARNIVAL ` + ship + `- Exterior`}
              aria-hidden="true"
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={awsUrl + `/media/ships/` + ship.replace(/ /g, "") + `/interior.jpg`}
              srcSet={awsUrl + `/media/ships/` + ship.replace(/ /g, "") + `/interior.jpg`}
              alt={`CARNIVAL ` + ship + `- Interior`}
              aria-hidden="true"
            />
          }
        />
      )}
      {!isMobile && (
        <ReactCompareSlider
          onPositionChange={(e) => {
            setClipPos(e);
          }}
          onPointerLeave={() => {
            triggerShipGAEvent();
          }}
          style={{
            height: "100vh",
            width: "100%",
            backgroundImage:
              "URL(https://s3.amazonaws.com/td.carnival/2024.celebration/assets/media/slider-arrows.svg)"
          }}
          onlyHandleDraggable={true}
          portrait={false}
          itemOne={
            <ReactCompareSliderImage
              src={awsUrl + `/media/ships/` + ship.replace(/ /g, "") + `/exterior.jpg`}
              srcSet={awsUrl + `/media/ships/` + ship.replace(/ /g, "") + `/exterior.jpg`}
              alt={`CARNIVAL ` + ship + `- Exterior`}
              aria-hidden="true"
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={awsUrl + `/media/ships/` + ship.replace(/ /g, "") + `/interior.jpg`}
              srcSet={awsUrl + `/media/ships/` + ship.replace(/ /g, "") + `/interior.jpg`}
              alt={`CARNIVAL ` + ship + `- Interior`}
              aria-hidden="true"
            />
          }
        />
      )}

      <div className="absolute left-1/2 -translate-x-1/2 bottom-[13%] z-[102] btn">
        {" "}
        {isTrade === false && (
          <a
            href={`https://www.carnival.com/cruise-search?pageNumber=1&numadults=2&ptport=CBK&pagesize=8&sort=fromprice&showBest=true&async=true&currency=USD&locality=1`}
            onClick={() => {
              triggerGAEvent("explore-sailings");
              window.gtag("event", "conversion", {
                allow_custom_scripts: true,
                u1: "[Number of Passengers]",
                u10: "[Military]",
                u11: "[Page URL]",
                u12: "[ga_cilent_id]",
                u13: "[vifp_number]",
                u14: "[Sail ID]",
                u2: "[Duration]",
                u3: "[Port]",
                u4: "[Ship]",
                u5: "[Destination]",
                u6: "[Sail Date]",
                u7: "[Stateroom]",
                u8: "[Booking ID]",
                u9: "[Home State]",
                send_to: "DC-10750860/carni0/carni04l+unique"
              });
            }}
            aria-label="Explore Sailings"
            className="bg-red uppercase text-white text-[19px] lg:text-[20px] font-bold  leading-none py-3 px-10 pt-3"
          >
            Explore&nbsp;Sailings
          </a>
        )}
      </div>
    </section>
  );
};

export default CompareSlider;
