import React from "react";
import PropTypes from "prop-types";

const ModuleSection = ({ children, className, fullscreen = true }) => {
  const full = fullscreen ? "h-screen" : "h-full lg:h-screen";
  return <div className={`w-full  ${full} relative overflow-hidden ${className}`}>{children}</div>;
};

ModuleSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string
};

export default ModuleSection;
