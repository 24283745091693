import React, { useEffect, useLayoutEffect } from "react";
import { awsUrl } from "../../helpers";
import { useScrollDetectors } from "../../hooks/useScrollDetectors";

import { FlyThrough, SideBySide } from "../../components/UnlockedPortal/Modules";
import {
  ModuleCopyBody,
  ModuleCopyHeadline,
  ModuleSideOneThird,
  ModuleSideTwoThirds
} from "../../components/UnlockedPortal/Modules/Parts";

import { UnlockedPortalWrapper } from "../../components/UnlockedPortal/Parts";

import { useUserContext } from "../../context/UserContext";

import UnlockedPortalIntroFoodBeverage from "../../components/UnlockedPortal/UnlockedPortalIntroFoodBeverage";

const FoodBeverage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const handleVideo = () => {
    if (allVideosPlaying) {
      setAllVideosPlaying(false);
    } else {
      setAllVideosPlaying(true);
    }
  };

  const { setCurrentPage, setAllVideosPlaying, allVideosPlaying } = useUserContext();

  setCurrentPage("sip-savor");
  useScrollDetectors("food-beverage");

  useEffect(() => {
    document.title = "Carnival | Celebration Key™ | Sip and Savor";
  }, []);

  return (
    <UnlockedPortalWrapper
      bgColorClass="portal-food-beverage bg-[#0057A0]"
      gaPrefix={`food-beverage`}
    >
      <div className="absolute z-[99] mt-2 right-2">
        {" "}
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => handleVideo()}
          onClick={() => handleVideo()}
          aria-label={(allVideosPlaying ? "Pause" : "Play") + " all videos"}
          className={"absolute top-0 right-0 block z-50 bg-dark-blue px-1"}
        >
          {!allVideosPlaying && (
            <span
              aria-hidden
              className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white"
            >
              play_arrow
            </span>
          )}
          {allVideosPlaying && (
            <span className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white">
              pause
            </span>
          )}
        </div>
      </div>
      <UnlockedPortalIntroFoodBeverage isPlaying={allVideosPlaying} />
      <SideBySide mobileReverse={true} classes={"mingos"}>
        <ModuleSideOneThird className="bg-white">
          <div className="px-4 md:px-10 text-center">
            <img
              className="mx-auto my-3 lg:mb-[25px] w-[120px] lg:w-[160px]"
              src={`${awsUrl}/media/portals/one-year/calypso-lagoon-dark.svg`}
              alt=""
              aria-hidden={true}
            />
            <ModuleCopyHeadline theme={"dark"}>
              Mingo’s Tropical <span className="block">Bar & Kitchen</span>
            </ModuleCopyHeadline>
            <ModuleCopyBody theme={"dark"}>
              Named for The Bahamas’ national bird, this is the place to go for delicious
              lagoon-side eats and tasty drinks in a lively atmosphere. Enjoy island favorites like
              jerk chicken and coconut shrimp, classics like burgers and tacos, and a menu of
              frozen, tropical drinks at the dry&nbsp;bar.
            </ModuleCopyBody>
          </div>
        </ModuleSideOneThird>
        <ModuleSideTwoThirds>
          <div className="w-full h-full relative overflow-hidden">
            <FlyThrough
              fullscreen={false}
              media={`image`}
              imgSrc={`${awsUrl}/media/food-beverage/Mingos_sitdown@2x`}
              gradient={false}
              isPlaying={false}
              showIcon={false}
              headline=""
              bodyCopy=""
            ></FlyThrough>
          </div>
        </ModuleSideTwoThirds>
      </SideBySide>
      <SideBySide mobileReverse={false} classes={"bbq"}>
        <ModuleSideTwoThirds>
          <div className="w-full h-full relative overflow-hidden">
            <FlyThrough
              fullscreen={false}
              media={`image`}
              imgSrc={`${awsUrl}/media/food-beverage/BBQimage-1@2x`}
              gradient={false}
              isPlaying={false}
              showIcon={false}
              headline=""
              bodyCopy=""
            ></FlyThrough>
          </div>
        </ModuleSideTwoThirds>
        <ModuleSideOneThird className="bg-white">
          <div className="px-4 md:px-10 text-center">
            <img
              className="mx-auto my-3 lg:mb-[25px] w-[120px] lg:w-[160px]"
              src={`${awsUrl}/media/portals/one-year/calypso-lagoon-dark.svg`}
              alt=""
            />
            <ModuleCopyHeadline theme={"dark"}>
              Surf N’ Sauce <span className="block">BBQ & Brews</span>{" "}
            </ModuleCopyHeadline>
            <ModuleCopyBody theme={"dark"}>
              Enjoy the slow-smoked meats prepared in an outdoor smoker at this barbeque and
              brewhouse joint, offering a full-service dining experience nestled among the palm
              trees right by the&nbsp;lagoon.
            </ModuleCopyBody>
          </div>
        </ModuleSideOneThird>
      </SideBySide>
      <SideBySide mobileReverse={true} classes={"gills"}>
        <ModuleSideOneThird className="bg-white">
          <div className="px-4 md:px-10 text-center">
            <img
              className="mx-auto mb-3 lg:mb-[25px] w-[128px] lg:w-[168px]"
              src={`${awsUrl}/media/portals/one-year/starfish-lagoon-dark.svg`}
              alt=""
            />
            <ModuleCopyHeadline theme={"dark"}>Gill’s Grill </ModuleCopyHeadline>
            <ModuleCopyBody theme={"dark"}>
              Enjoy Caribbean seafood including seafood baskets, local fish and lobster, along with
              land-based favorites such as chicken, burgers, kid's meals and more - all served al
              fresco by the family friendly&nbsp;lagoon.
            </ModuleCopyBody>
          </div>
        </ModuleSideOneThird>
        <ModuleSideTwoThirds>
          <div className="w-full h-full relative overflow-hidden">
            <FlyThrough
              fullscreen={false}
              media={`image`}
              imgSrc={`${awsUrl}/media/food-beverage/GillsGrill@2x`}
              gradient={false}
              isPlaying={false}
              showIcon={false}
              headline=""
              bodyCopy=""
            ></FlyThrough>
          </div>
        </ModuleSideTwoThirds>
      </SideBySide>

      <FlyThrough
        media={`image`}
        imgSrc={`${awsUrl}/media/food-beverage/FoodHall`}
        isPlaying={false}
        showIcon={true}
        iconSrc={`${awsUrl}/media/portals/one-year/CK_Lockups_TM_Starfish_Lagoon.svg`}
        headline="Captain’s Galley Food&nbsp;Hall"
        bodyCopy="Serving up burritos and Mediterranean bowls, subs, burgers and hot dogs, salads, desserts and more. The food hall is perfect for a quick lunch where everyone in the family will find something they’ll&nbsp;enjoy."
      ></FlyThrough>
      <FlyThrough
        media={`image`}
        imgSrc={`${awsUrl}/media/food-beverage/FoodTrucks`}
        isPlaying={false}
        showIcon={true}
        icon2Src={`${awsUrl}/media/portals/one-year/CK_Lockups_TM_Calypso_Lagoon.svg`}
        iconSrc={`${awsUrl}/media/portals/one-year/CK_Lockups_TM_Starfish_Lagoon.svg`}
        headline="So many food trucks, so much to try"
        bodyCopy="In Calypso Lagoon and Starfish Lagoon, taste your way through an assortment of food trucks, all operated by local vendors. Whether you choose a beachside classic, like chicken sandwiches and burgers, or an authentic, Bahamian delight, like conch fritters, you’re sure to find a new favorite&nbsp;dish."
      ></FlyThrough>
    </UnlockedPortalWrapper>
  );
};

export default FoodBeverage;
