import React, { useState } from "react";
import { useScreenDetector } from "../../hooks/useScreenDetector";

const QuizSlider = ({ question, idx, setAnswer }) => {
  const { isMobile, isDesktop } = useScreenDetector();

  const [currentOption, setCurrentOption] = useState("middle");

  const onChange = (e) => {
    setAnswer(idx, e.target.value);
    if (e.target.value < 34) {
      setCurrentOption("left");
    } else if (e.target.value > 66) {
      setCurrentOption("right");
    } else {
      setCurrentOption("middle");
    }
  };

  return (
    <div className="max-w-[775px] w-full mx-auto mt-2 lg:my-8">
      <label
        className="font-semibold text-light-blue text-[19px] lg:text-[20px]"
        htmlFor={`question` + (idx + 1)}
      >
        {question.question}…
      </label>
      <div className="relative mb-6 lg:mb-16 mt-8 leading-4">
        <div>
          <div className="bg-[#0091D0] h-3 w-3 rounded-full absolute -top-1 left-0"></div>
          <div className="bg-[#0091D0] h-3 w-3 rounded-full absolute -top-1 right-0"></div>
          <input
            type="range"
            min={question.left}
            max={question.right}
            defaultValue={50}
            className="slider my-8 w-full"
            id={`question` + (idx + 1)}
            onChange={onChange}
            aria-valuetext={question.question + ` ` + question.answers[currentOption].option}
          />
        </div>
        <div className="mt-6 lg:mt-7 flex justify-between font-semibold text-[15px] lg:text-[16.5px] lg:leading-[22px]">
          {isDesktop && (
            <>
              <div className="text-left md:w-40">{question.answers.left.option}</div>
              <div className="text-center md:w-40">{question.answers.middle.option}</div>
              <div className="text-right md:w-40">{question.answers.right.option}</div>
            </>
          )}
          {isMobile && (
            <div className="w-full text-center">{question.answers[currentOption].option}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizSlider;
