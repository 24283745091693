import React, { useRef, useEffect } from "react";
import { awsUrl } from "../../helpers";
import { useScrollDetectors } from "../../hooks/useScrollDetectors";
import { useUserContext } from "../../context/UserContext";

import { UnlockedPortalWrapper } from "../../components/UnlockedPortal/Parts";
import UnlockedPortalIntro from "../../components/UnlockedPortal/UnlockedPortalIntro";

import { useGSAP } from "../../gsap/react";
import { gsap } from "../../gsap";
import { SplitText } from "../../gsap/SplitText";

import { FlyThrough, Overlay } from "../../components/UnlockedPortal/Modules";
import { ModuleCopyBody, ModuleCopyHeadline } from "../../components/UnlockedPortal/Modules/Parts";

gsap.registerPlugin(SplitText, useGSAP);

const Retail = () => {
  const { setCurrentPage, setAllVideosPlaying, allVideosPlaying } = useUserContext();
  setCurrentPage("starfish-lagoon");
  const headlineTl = useRef();

  useScrollDetectors("retail-portal");

  useEffect(() => {
    document.title = "Carnival | Celebration Key™ | Starfish Lagoon";
  }, []);

  useGSAP(() => {
    let titleRef = new SplitText("#market-headline", { type: "chars, words" });
    let captionRef = new SplitText("#market-body", { type: "lines" });

    const splitRevert = () => {
      captionRef.revert();
    };
    const splitRevertHeadline = () => {
      titleRef.revert();
    };

    headlineTl.current = gsap
      .timeline({
        scrollTrigger: {
          start: "top center",
          trigger: "#market-flythrough"
          //markers: true
        }
      })
      .from(titleRef.chars, {
        duration: 0.65,
        x: 10,
        autoAlpha: 0,
        stagger: 0.05,
        onComplete: splitRevertHeadline
      })
      .from(
        captionRef.lines,
        {
          duration: 0.65,
          y: 20,
          autoAlpha: 0,
          stagger: 0.03,
          onComplete: splitRevert
        },
        ">"
      )
      .fromTo("#market-link", { opacity: 0 }, { opacity: 1 });
  }, {});

  const slidesoverlay = [
    {
      video: "portals/family-friendly/unlocked/Family_Section2_SandCastle",
      headline: "",
      bodyCopy: ""
    },
    {
      video: "portals/family-friendly/unlocked/Family_Section2_Aquabanas",
      headline: "",
      bodyCopy: ""
    }
  ];

  const handleVideo = () => {
    if (allVideosPlaying) {
      setAllVideosPlaying(false);
    } else {
      setAllVideosPlaying(true);
    }
  };

  return (
    <UnlockedPortalWrapper bgColorClass="bg-[#6470B5]" gaPrefix={`ff`}>
      <div className="absolute z-[99] mt-2 right-2">
        {" "}
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => handleVideo()}
          onClick={() => handleVideo()}
          aria-label={(allVideosPlaying ? "Pause" : "Play") + " all videos"}
          className={"absolute top-0 right-0 block z-50 bg-dark-blue px-1"}
        >
          {!allVideosPlaying && (
            <span
              aria-hidden
              className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white"
            >
              play_arrow
            </span>
          )}
          {allVideosPlaying && (
            <span className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white">
              pause
            </span>
          )}
        </div>
      </div>
      <UnlockedPortalIntro isPlaying={allVideosPlaying} portal="family-friendly" />
      <Overlay
        slides={slidesoverlay}
        autospeed={9700}
        isSlideshow={true}
        id={"slideoverlay"}
        className={"overlaybkg"}
        ariaHidden={true}
      >
        <div className="flex flex-col items-center">
          {" "}
          <ModuleCopyHeadline theme={"dark"}>
            Lounge in our freshwater&nbsp;lagoon
          </ModuleCopyHeadline>
          <ModuleCopyBody theme={"dark"} className={``}>
            <div className="relative">
              <span className={`absolute left-0 top-0 w-full text-animated-1`}>
                At the heart of Starfish Lagoon is the largest freshwater lagoon in the Caribbean.*
                Choose your relaxation spot in one of our variety of lagoon-side cabanas, then swim,
                float, and chill all day in paradise with your favorite&nbsp;crew.
                <small className="block leading-[16px] text-[12px] mt-6 lg:mt-28">
                  *Based on publicly available data of surface area measurements as of 01/22/24.
                </small>
              </span>
            </div>
          </ModuleCopyBody>
        </div>
      </Overlay>
      <FlyThrough
        media={`video`}
        videoHeight={"h-screen"}
        videoName={`portals/family-friendly/unlocked/Family_Section3`}
        isPlaying={true}
        headline="Where little guests make a big&nbsp;splash"
        bodyCopy="Kids will soak it all in at Guppy Grotto, our coral-reef themed splash pad with 2-foot-deep wading pool and loads of fun water toys. Plus, with a nearby ice cream spot and a place for adults to grab a drink, everyone feels totally&nbsp;refreshed. "
      />
      <FlyThrough
        media={`image`}
        imgSrc={`${awsUrl}/media/portals/family-friendly/unlocked/sportscourt-r1`}
        isPlaying={false}
        headline="Have a ball on the Sports Court"
        bodyCopy="We’ll bring the gear, you bring the friendly competition! Go head-to-head with your friends and family in volleyball, basketball, pickleball, and&nbsp;more."
      ></FlyThrough>
      <FlyThrough
        media={`video`}
        videoHeight={"h-screen"}
        videoName={`portals/family-friendly/unlocked/section5-new`}
        isPlaying={false}
        headline="Sun. Sand. Paradise."
        bodyCopy="In Starfish Lagoon’s dedicated beach area, a picture-perfect scene awaits. Relax in a large cabana. Float in the pristine waters. Build a sandcastle with the kids. Here, there’s paradise for your entire&nbsp;crew."
        link="Explore sailings to Celebration Key™&nbsp;»"
        linkId={`sun-link`}
        gaTriggerEvent={`ff_explore-sailings_2`}
      ></FlyThrough>
    </UnlockedPortalWrapper>
  );
};

export default Retail;
