import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { awsUrl, triggerGAEvent } from "../../helpers";

import { gsap } from "../../gsap";
import { SplitText } from "../../gsap/SplitText";
import { useGSAP } from "../../gsap/react";

gsap.registerPlugin(useGSAP, SplitText, useGSAP);

const UnlockedPortalLanding = ({ portal, className }) => {
  const headlineTl = useRef();
  const imgTl = useRef();

  useGSAP(() => {
    let headlineRef = new SplitText("#featured-headline", { type: "chars, words" });
    let bodyRef = new SplitText("#featured-copy span", {
      type: "lines",
      linesClass: "line line++"
    });

    function splitRevert() {
      headlineRef.revert();
      bodyRef.revert();
    }

    headlineTl.current = gsap
      .timeline({
        scrollTrigger: {
          start: "top center",
          trigger: "#just-unlocked"
          // markers: true
        }
      })
      .fromTo("#unlocked-bg", { opacity: 0 }, { opacity: 1 }, "0")
      .fromTo("#feature-icon", { opacity: 0, marginTop: "10px" }, { opacity: 1, marginTop: 0 }, "0")
      .from(
        headlineRef.chars,
        {
          duration: 0.65,
          y: 20,
          autoAlpha: 0,
          stagger: 0.03
        },
        "<"
      )
      .from(
        bodyRef.lines,
        {
          duration: 0.65,
          y: 10,
          autoAlpha: 0,
          stagger: 0.05,
          onComplete: splitRevert
        },
        "<+=1"
      )
      .fromTo("#featured-cta", { opacity: 0 }, { opacity: 1 }, "<+=0.5");

    if (morePortalName && morePortalLink) {
      headlineTl.current.fromTo("#featured-caption", { opacity: 0 }, { opacity: 1 }, "<");
    }

    imgTl.current = gsap
      .timeline({
        scrollTrigger: {
          start: "top center",
          trigger: "#just-unlocked"
          // markers: true
        },
        repeat: -1,
        yoyo: true
      })
      .set("#bg", { backgroundPosition: "80% 50%", scale: 1 })
      .to("#bg", { backgroundPosition: "20% 50%", duration: 10 }, "<");
  }, {});

  const portalData = {
    "adult-friendly": {
      image: `${awsUrl}/media/portals/adult-friendly/bg-unlocked@2x.jpg?v=2`,
      portalKey: "adult-friendly",
      portalName: "Calypso Lagoon",
      portalColor: "bg-[#ED196E]",
      portalLink: "/portals/calypso-lagoon",
      copy: "We’re excited to reveal Calypso Lagoon, the adult-friendly Portal at Celebration Key™. With a full-service restaurant, swim up bar, DJ stage and more, no matter what you choose, you’ll find what you need for an unforgettable day in paradise.",
      icon: `${awsUrl}/media/portals/adult-friendly/icon.svg`,
      morePortalName: "welcome Portal, Paradise Plaza",
      morePortalLink: "/portals/retail"
    },
    retail: {
      image: `${awsUrl}/media/portals/retail/unlocked/retail-still.jpg?v=2`,
      portalKey: "retail",
      portalName: "Lokono Cove",
      portalColor: "bg-[#00c8d3]",
      portalLink: "/portals/lokono-cove",
      copy: "We’re excited to reveal Lokono Cove, the retail Portal at Celebration Key™. Here you can celebrate Bahamian culture, arts, and flavors as you shop locally crafted, authentic Bahamian goods.",
      icon: `${awsUrl}/media/portals/retail/icon.svg`,
      morePortalName:
        "Take a first look at the newly unlocked adult-friendly Portal, Calypso Lagoon",
      morePortalLink: "/portals/calypso-lagoon"
    },
    "family-friendly": {
      image: `${awsUrl}/media/portals/family-friendly/unlocked/splashpad.jpg?v=2`,
      portalKey: "family-friendly",
      portalName: "Starfish Lagoon",
      portalColor: "bg-[#6470B5]",
      portalLink: "/portals/starfish-lagoon",
      copy: "We’re excited to reveal <strong>Starfish Lagoon</strong> at Celebration Key™. With a freshwater lagoon, splash pad, and beach area, it’s a place for the whole family to discover paradise and make memories. ",
      icon: `${awsUrl}/media/portals/family-friendly/icon.svg`,
      morePortalName: "Check out the adult-friendly Portal, Calypso Lagoon",
      morePortalLink: "/portals/calypso-lagoon"
    },
    "private-club": {
      image: `${awsUrl}/media/portals/private-club/pearl-cove-bg@2x.jpg`,
      portalKey: "private-club",
      portalPreName: "Adults-Only Private Club",
      portalName: "Pearl Cove",
      portalColor: "bg-ck-private",
      portalLink: "/portals/pearl-cove",
      copy: "We’re excited to reveal Pearl Cove Beach Club at Celebration Key<sup>&trade;</sup>. From its inviting infinity pool to stunning beach views, this is the ultimate paradise experience <span class='inline-block'>for guests 18+.</span>",
      icon: `${awsUrl}/media/portals/private-club/icon.svg`,
      hideImageMb: true
    },
    "food-beverage": {
      image: `${awsUrl}/media/food-beverage/FoodHall.jpg`,
      portalKey: "Sip & Savor at Celebration&nbsp;Key",
      portalPreName: "Learn More",
      portalName: "Your ultimate flavor&nbsp;paradise",
      portalColor: "bg-[#0C1F46]",
      portalLink: "/sip-savor",
      copy: "Discover more than 30 venues, from delicious, full dining experiences to quick and tasty bites to tropical cocktails. There’s something for every&nbsp;tastebud.",
      icon: `${awsUrl}/media/food-beverage/icon.svg`,
      hideImageMb: false
    }
  };

  const {
    image,
    portalName,
    portalLink,
    copy,
    icon,
    morePortalName,
    morePortalLink,
    portalKey,
    hideImageMb
  } = portalData[portal];

  return (
    <section id="just-unlocked" data-tag="section_3B_fp-food-beverage" data-pixel="">
      <div className={`w-full h-auto lg:h-screen relative overflow-hidden ${className}`}>
        <div className="flex flex-col lg:flex-none lg:flex-row relative w-full h-full lg:flex justify-end">
          <div
            id="bg"
            className={`lg:absolute bg-cover md:h-full w-full lg:w-[67%] lg:left-0  bg-center ${
              hideImageMb ? "hidden lg:block" : "h-[300px]"
            }`}
            style={{ backgroundImage: "url(" + image + ")" }}
          />

          <div
            id="unlocked-bg"
            className="z-10 w-full flex-grow lg:flex-none lg:w-[42%]  pb-10 lg:pb-0 h-auto md:flex bg-white  items-center justify-center"
          >
            <div className="text-center text-dark-blue px-[30px]">
              <div className="flex flex-col lg:flex-row items-center justify-center lg:mb-2">
                <div id="featured-headline" className="pt-[25px] lg:pt-0">
                  <div
                    id="feature-icon"
                    className={`mx-auto text-center ${hideImageMb ? "hidden lg:block" : ""}`}
                  >
                    <img
                      src={icon}
                      alt={`${portalName} Icon`}
                      className=" h-[60px] lg:h-[83px] mx-auto mb-3"
                      aria-hidden="true"
                    />
                  </div>

                  <div className="text-[20px] uppercase font-semibold text-dark-blue leading-[25px]">
                    Just unlocked:
                  </div>
                  <div
                    className="text-[20px] uppercase font-semibold text-dark-blue leading-[25px] mb-3"
                    dangerouslySetInnerHTML={{ __html: `${portalKey}` }}
                  ></div>
                  <h2
                    className="text-[32px] leading-[32px] lg:text-[55px] font-bold text-millik lg:my-2 lg:leading-[57px]"
                    dangerouslySetInnerHTML={{ __html: `${portalName}` }}
                  ></h2>
                </div>
              </div>

              <p
                id="featured-copy"
                className="text-[16px] leading-[22px] lg:text-[19px] lg:leading-[25px] mb-[10px] lg:mb-[25px] max-w-[446px] mx-auto"
              >
                <span dangerouslySetInnerHTML={{ __html: `${copy}` }} />
              </p>
              <Link
                id="featured-cta"
                to={portalLink}
                aria-label={"View full details of Sip & Savor"}
                onClick={() => {
                  triggerGAEvent("section_3B_fp-link_food");
                }}
                class="bg-[#0C1F46] text-white uppercase text-[20px] font-bold h-12 leading-none px-10 lg:px-20 py-2"
              >
                View Full Details{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

UnlockedPortalLanding.prototype = {
  portal: PropTypes.string.isRequired
};

export default UnlockedPortalLanding;
