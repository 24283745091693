import React, { useRef } from "react";
import { ModuleSection } from "./Modules/Parts";
import Video from "../../common/Video";
import PropTypes from "prop-types";
import { awsUrl, triggerGAEvent } from "../../helpers";
import { useUserContext } from "../../context/UserContext";

import { gsap } from "../../gsap";
import { SplitText } from "../../gsap/SplitText";
import { useGSAP } from "../../gsap/react";

gsap.registerPlugin(SplitText, useGSAP);

const UnlockedPortalIntro = ({ portal, className }) => {
  const portalData = {
    welcome: {
      video: "portals/welcome/unlocked/flythrough",
      portalName: "Paradise Plaza",
      copy: "In the welcome Portal, find your way to paradise. With the iconic Suncastle ahead, and Caribbean music filling the air, this is your picturesque departure point to all things Celebration Key™.",
      icon: `${awsUrl}/media/portals/welcome/new/CK_Paradise_Plaza_Icon.svg`,
      iconWidth: `w-[65px] lg:w-[94px]`
    },
    "adult-friendly": {
      video: "portals/adult-friendly/unlocked/flythrough",
      portalName: "Calypso Lagoon",
      copy: "This adult-friendly Portal is the perfect spot to follow your own beat, whether your vibe is lively or laid-back. Named for the enchanting style of Caribbean music, here you’ll find a pristine stretch of beach, a freshwater lagoon, and so much more.",
      icon: `${awsUrl}/media/portals/adult-friendly/icon.svg`,
      iconWidth: `w-[70px] lg:w-[77px]`
    },
    retail: {
      video: "portals/retail/unlocked/flythrough",
      portalName: "Lokono Cove",
      copy: "In our retail Portal, you'll discover handcrafted treasures and explore a colorful space dedicated to honoring the essence of Bahamian culture.",
      icon: `${awsUrl}/media/portals/retail/icon.svg`,
      iconWidth: `w-[80px] lg:w-[87px]`
    },
    "family-friendly": {
      video: "portals/family-friendly/unlocked/flythrough",
      portalName: "Starfish Lagoon",
      copy: "In our family-friendly Portal, discover paradise for the whole family, with something for everyone, and endless ways to make unforgettable memories&nbsp;together.",
      icon: `${awsUrl}/media/portals/family-friendly/icon.svg`,
      iconWidth: `w-[80px] lg:w-[87px]`
    },
    "private-club": {
      image: "portals/private-club/unlocked/featured.png",
      portalName: "Private Portal",
      copy: "NAME, our adults-only private club Portal, is the ultimate paradise experience for guests 18+. Lounge by the infinity pool or crystal-clear ocean, savor fresh flavors, and enjoy stunning views throughout this exclusive, private retreat.",
      subCopy:
        "<span class='text-dark-blue block font-bold'>Sailing to Celebration Key™ on your next Carnival cruise?</span> Reservations are required to enjoy NAME.",
      icon: `${awsUrl}/media/portals/private-club/icon.svg`,
      iconWidth: `w-[80px] lg:w-[87px]`,
      bookNow: true
    }
  };

  const { video, image, portalName, copy, subCopy, icon, iconWidth, bookNow } = portalData[portal];

  const panTl = useRef();
  const { allVideosPlaying } = useUserContext();

  const handleVideo = () => {
    let curVideo = document.getElementsByClassName("portal-intro")[0].querySelector("video");
    curVideo.playsInline = true;
    curVideo.muted = true;
    curVideo.currentTime = 0;


    if (allVideosPlaying) {
      curVideo && curVideo.play();
    }

  };

  useGSAP(() => {
    let titleRef = new SplitText("#featured-hero-headline span", { type: "chars, words" });
    let captionRef = new SplitText("#featured-hero-body span", {
      type: "lines",
      linesClass: "line line++"
    });

    const splitRevert = () => {
      captionRef.revert();
    };

    panTl.current = gsap
      .timeline({
        onStart: () => {
          video && handleVideo();
        }
      })
      .fromTo(
        "#featured-logo",
        { opacity: 1 },
        { opacity: 0, duration: 0.5, ease: "ease.out", delay: 1.25 }
      )
      .fromTo("#featured-icon", { opacity: 0 }, { opacity: 1, duration: 0.5, ease: "ease.out" })
      .from(
        titleRef.chars,
        {
          duration: 0.65,
          x: 10,
          autoAlpha: 0,
          stagger: 0.05
        },
        "<"
      )
      .fromTo(
        " #featured-hero-headline",
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: "ease.out" },
        "<"
      )
      .from(
        captionRef.lines,
        {
          duration: 0.45,
          y: 20,
          autoAlpha: 0,
          stagger: 0.05,
          onComplete: splitRevert
        },
        ">"
      )
      .from(
        " #featured-hero-body-2",
        {
          duration: 0.45,
          y: 20,
          autoAlpha: 0
        },
        ">"
      );
  }, {});

  return (
    <ModuleSection className={`flex flex-col mt-[55px] portal-intro`}>
      {video && (
        <Video
          height={`h-[40%] lg:h-[60%]`}
          poster={video}
          name={video}
          autoplay={allVideosPlaying}
          videoplaying={allVideosPlaying}
          position={`top`}
        ></Video>
      )}
      {image && (
        <div className="h-[40%] lg:h-[60%]">
          <img
            aria-hidden={true}
            src={`${awsUrl}/media/${image}`}
            alt={`${portalName} Portal`}
            className="w-full h-full object-cover object-center"
          />
        </div>
      )}
      <div
        id="featured-hero"
        className={`absolute z-10 top-0 w-full flex flex-col md:flex-row ` + className}
      >
        <img
          id="featured-logo"
          src={awsUrl + "/media/one-year/ck-logo-white.svg?v=1"}
          alt="Celebration Key"
          className="w-[186px] lg:w-[300px] mx-auto mt-4 "
        />
      </div>
      <div className="flex-grow bg-white w-full text-center">
        <div className="bg-white py-8 px-[24px] lg:p-8 w-full flex flex-col justify-center h-full">
          {" "}
          <div className="flex flex-col lg:flex-row items-center justify-center max-w-[801px] mx-auto">
            <div>
              {" "}
              <img
                className={`mx-auto mb-3 ${iconWidth} drop-shadow-white lg:mr-6`}
                id="featured-icon"
                src={icon}
                alt={`${portalName} icon`}
                aria-hidden={true}
              />
            </div>
            <div>
              {" "}
              <h1
                id="featured-hero-headline"
                className="text-[38px] lg:text-[40px] text-[#052049] leading-[46px] mb-[13px] lg:mb-4 text-millik drop-shadow-white text-center"
                aria-label={`Welcome to ${portalName}`}
              >
                <span className="text-[32px] lg:text-[38px]" aria-hidden>
                  {" "}
                  Welcome to
                </span>{" "}
                <span
                  className="block text-millik text-[45px] lg:text-[60px] text-center tracking-tight text-nowrap -ml-2 lg:ml-0"
                  aria-hidden
                >
                  {portalName}
                </span>
              </h1>
            </div>
          </div>
          <p
            id="featured-hero-body"
            className="mb-4 text-[19px] leading-[25px] max-w-[901px] mx-auto text-center"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: `${copy}`
              }}
            ></span>
          </p>
          {subCopy && (
            <p
              id="featured-hero-body-2"
              className="text-[19px] leading-[25px] max-w-[901px] mx-auto text-center text-[#00C8D3]"
              dangerouslySetInnerHTML={{
                __html: `${subCopy}`
              }}
            ></p>
          )}
          {bookNow && (
            <a
              href={`https://www.carnival.com/cruise-search?pageNumber=1&numadults=2&ptport=CBK&pagesize=8&sort=fromprice&showBest=true&async=true&currency=USD&locality=1`}
              onClick={() => {
                triggerGAEvent("book-now");
              }}
              aria-label="Explore Sailings"
              className="mt-4 bg-[#00C8D3] uppercase text-white text-[19px] lg:text-[20px] font-bold leading-none py-3 px-8 w-[319px] mx-auto inline-block"
            >
              Book Now
            </a>
          )}
        </div>
      </div>
    </ModuleSection>
  );
};

UnlockedPortalIntro.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  portal: PropTypes.string.isRequired
};

export default UnlockedPortalIntro;
