import React, { useRef } from "react";
import PropTypes from "prop-types";
import { awsUrl, triggerGAEvent } from "../../helpers";

import { gsap } from "../../gsap";
import { SplitText } from "../../gsap/SplitText";
import { useGSAP } from "../../gsap/react";
import Video from "../../common/Video";
import { useUserContext } from "../../context/UserContext";

gsap.registerPlugin(SplitText, useGSAP);

const UnlockedPortalIntroPrivate = ({ className }) => {
  const panTl = useRef();
  const { allVideosPlaying, isTrade } = useUserContext();

  useGSAP(() => {
    let titleRef = new SplitText("#featured-hero-headline span", { type: "chars, words" });
    let captionRef = new SplitText("#featured-hero-body span", {
      type: "lines",
      linesClass: "line line++"
    });

    const splitRevert = () => {
      captionRef.revert();
    };

    panTl.current = gsap
      .timeline({})
      .fromTo(
        "#featured-hero",
        { opacity: 1 },
        { opacity: 0, duration: 0.5, ease: "ease.out", delay: 1.75 }
      )
      .fromTo("#featured-icon", { opacity: 0 }, { opacity: 1, duration: 0.5, ease: "ease.out" })
      .from(
        titleRef.chars,
        {
          duration: 0.65,
          x: 10,
          autoAlpha: 0,
          stagger: 0.05
        },
        "<"
      )
      .fromTo(
        "#featured-hero-headline",
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: "ease.out" },
        "<"
      )
      .from(
        captionRef.lines,
        {
          duration: 0.45,
          y: 20,
          autoAlpha: 0,
          stagger: 0.05,
          onComplete: splitRevert
        },
        ">"
      )
      .from(
        "#featured-hero-body",
        {
          duration: 0.45,
          y: 20,
          autoAlpha: 0,
          delay: 0.5
        },
        ">"
      )
      .from(
        "#featured-hero-body-2",
        {
          duration: 0.45,
          y: 20,
          autoAlpha: 0
        },
        ">"
      )
      .from(
        "#featured-hero-button",
        {
          duration: 0.45,
          y: 20,
          autoAlpha: 0
        },
        ">"
      );
  }, {});

  return (
    <div className={`w-full relative overflow-hidden mt-[55px]`}>
      <Video
        height={`h-[400px] lg:h-[500px]`}
        poster={`portals/private-club/hero`}
        name={`portals/private-club/hero`}
        autoplay={allVideosPlaying}
        videoplaying={allVideosPlaying}
        position={`bottom`}
      ></Video>
      <div
        id="featured-hero"
        className={`absolute z-10 top-0 w-full flex flex-col md:flex-row ` + className}
      >
        <img
          id="featured-logo"
          src={awsUrl + "/media/one-year/ck-logo-white.svg?v=1"}
          alt="Celebration Key"
          className="w-[186px] lg:w-[300px] mx-auto mt-4 z-10 "
        />
        <div className="absolute w-[400px] h-[400px] left-1/2 -translate-x-1/2 -top-[70px] rounded-full bg-radial-gradient-shadow z-0"></div>
      </div>
      <div className="flex-grow bg-white w-full text-center">
        <div className="bg-white py-8 px-[24px] lg:p-8 w-full flex flex-col justify-center h-full">
          <div className="flex flex-col lg:flex-row items-center justify-center max-w-[801px] mx-auto">
            <div>
              <img
                className={`mx-auto mb-3 w-[80px] lg:w-[87px] drop-shadow-white lg:mr-6`}
                id="featured-icon"
                src={`${awsUrl}/media/portals/private-club/icon.svg`}
                alt={`Private Portal icon`}
                aria-hidden="true"
              />
            </div>
            <div>
              <h1
                id="featured-hero-headline"
                className="text-[38px] lg:text-[40px] text-[#052049] leading-[46px] mb-[13px] lg:mb-4 text-millik drop-shadow-white text-center"
                aria-label="Welcome to Pearl Cove Beach Club"
              >
                <span aria-hidden="true" className="text-[32px] lg:text-[38px]">
                  {" "}
                  Welcome to
                </span>{" "}
                <span
                  aria-hidden="true"
                  className="block text-millik text-[45px] lg:text-[60px] text-center tracking-tight text-nowrap -ml-2 lg:ml-0 leading-none"
                >
                  Pearl Cove
                  <span aria-hidden="true" className="text-[35px] lg:text-[47px] block -mt-1">
                    Beach Club
                  </span>
                </span>
              </h1>
            </div>
          </div>
          <p
            id="featured-hero-body"
            className="mb-4 text-[19px] leading-[25px] max-w-[901px] mx-auto text-center"
          >
            <span>
              Pearl Cove Beach Club, our adults-only private club Portal, is the ultimate paradise
              experience for guests 18+. Lounge by the infinity pool or crystal-clear ocean, savor
              fresh flavors, and enjoy stunning views throughout this exclusive{" "}
              <span className="inline-block">beach club.</span>
            </span>
          </p>
          {!isTrade && (
            <>
              <p
                id="featured-hero-body-2"
                className="text-[19px] leading-[25px] max-w-[901px] mx-auto text-center text-dark-blue"
              >
                Reserve your spot in this <span className="inline-block">private paradise.</span>
              </p>
              <a
                id="featured-hero-button"
                href="https://www.carnival.com/shore-excursions/celebration-key?icid=icp_ccl_ck_microsite_shorex"
                onClick={() => {
                  triggerGAEvent("pc_book-now");
                }}
                aria-label="Book Now"
                className="mt-4 bg-dark-blue uppercase text-white text-[19px] lg:text-[20px] font-bold leading-none py-3 px-8 w-[319px] mx-auto inline-block"
              >
                Book Now
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

UnlockedPortalIntroPrivate.propTypes = {
  isPlaying: PropTypes.bool.isRequired
};

export default UnlockedPortalIntroPrivate;
