import React, { useState } from "react";
import { triggerGAEvent } from "../../helpers";
import { useUserContext } from "../../context/UserContext";
import { NavBarDropdown, NavBarHamburger } from "./index";

const NavBar = ({ homepage }) => {
  const { isTrade } = useUserContext();
  const [navVisible, setNavVisible] = useState(false);

  const handleKeyPress = (e) => {
    // console.log(e.keyCode);
    if (e.keyCode === 13 || e.keyCode === 32) {
      //check either enter or spacebar
      setNavVisible(true);
    }
  };

  return (
    <nav className="fixed top-0 z-[150] w-full h-[55px] bg-gradient-to-b from-dark-blue-2 to-dark-blue flex items-center justify-center">
      <div className="container px-4 mx-auto flex justify-end items-center">
        {!isTrade && (
          <div className="absolute left-1/2 -translate-x-1/2 lg:relative lg:translate-x-0 lg:left-auto lg:mr-20">
            <a
              href={`https://www.carnival.com/cruise-search?pageNumber=1&numadults=2&ptport=CBK&pagesize=8&sort=fromprice&showBest=true&async=true&currency=USD&locality=1`}
              onClick={() => {
                triggerGAEvent("explore-sailings-top");
                window.gtag("event", "conversion", {
                  allow_custom_scripts: true,
                  u1: "[Number of Passengers]",
                  u10: "[Military]",
                  u11: "[Page URL]",
                  u12: "[ga_cilent_id]",
                  u13: "[vifp_number]",
                  u14: "[Sail ID]",
                  u2: "[Duration]",
                  u3: "[Port]",
                  u4: "[Ship]",
                  u5: "[Destination]",
                  u6: "[Sail Date]",
                  u7: "[Stateroom]",
                  u8: "[Booking ID]",
                  u9: "[Home State]",
                  send_to: "DC-10750860/carni0/carni055+unique"
                });
              }}
              aria-label="Explore Sailings"
              className="block bg-white uppercase text-dark-blue text-[12px] lg:text-[15px] font-bold leading-none py-3 px-4 lg:px-8"
            >
              Explore&nbsp;Sailings
            </a>
          </div>
        )}
        <NavBarHamburger
          onClick={() => setNavVisible(true)}
          color={"#ffffff"}
          onKeyDown={(e) => handleKeyPress(e)}
        />
      </div>
      <NavBarDropdown
        setNavVisible={setNavVisible}
        navVisible={navVisible}
        gaPrefix={"home"}
        homepage={homepage}
      />
    </nav>
  );
};

export default NavBar;
